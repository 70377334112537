import { lazy } from "react";

const PagesRoutes = [
  // Authentication
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/auth/login")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../../view/pages/auth/forgot-password")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/resend-forgot-password",
    component: lazy(() =>
      import("../../view/pages/auth/resend-email/forgot-password")
    ),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/reset-password",
    component: lazy(() => import("../../view/pages/auth/reset-password")),
    layout: "FullLayout",
    auth: false,
  },

  // Main Pages
  {
    path: "/dashboard",
    exact: true,
    component: lazy(() => import("../../view/pages/dashboard")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
  // Other
  {
    path: "/system/data",
    component: lazy(() => import("../../view/pages/system/data")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    access: ["system-index"],
  },
  {
    path: "/system/kunjungan",
    component: lazy(() => import("../../view/pages/system/kunjungan")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    access: ["system-index"],
  },
  {
    path: "/system/detail-kunjungan",
    component: lazy(() => import("../../view/pages/system/detail-kunjungan")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    access: ["system-index"],
  },
  {
    path: "/system/probabilitas",
    component: lazy(() => import("../../view/pages/system/probability")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    access: ["system-index"],
  },
  {
    path: "/system/dealing",
    component: lazy(() => import("../../view/pages/system/dealing")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    access: ["system-index"],
  },
  {
    path: "/system/detail-dealing",
    component: lazy(() => import("../../view/pages/system/detail-dealing")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    access: ["system-index"],
  },
  {
    path: "/system/form",
    component: lazy(() => import("../../view/pages/system/form")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    access: ["system-index"],
  },
  {
    path: "/settings",
    component: lazy(() => import("../../view/pages/settings")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    access: ["system-index"],
  },
  {
    path: "/criteria/data",
    component: lazy(() => import("../../view/pages/criteria/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["criteria-index"],
  },
  {
    path: "/criteria/form",
    component: lazy(() => import("../../view/pages/criteria/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["criteria-store", "criteria-update"],
  },
  {
    path: "/bobot/data",
    component: lazy(() => import("../../view/pages/bobot/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["probability-index"],
  },
  {
    path: "/bobot/form",
    component: lazy(() => import("../../view/pages/bobot/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["probability-store", "probability-update"],
  },
  {
    path: "/insurance-fee/data",
    component: lazy(() => import("../../view/pages/insurance-fee/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["probability-index"],
  },
  {
    path: "/insurance-fee/form",
    component: lazy(() => import("../../view/pages/insurance-fee/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["probability-store", "probability-update"],
  },
  {
    path: "/user/data",
    component: lazy(() => import("../../view/pages/user/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["user-index"],
  },
  {
    path: "/user/form",
    component: lazy(() => import("../../view/pages/user/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["user-store", "user-update"],
  },
  {
    path: "/role/data",
    component: lazy(() => import("../../view/pages/role/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["role-index"],
  },
  {
    path: "/role/form",
    component: lazy(() => import("../../view/pages/role/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["role-store", "role-update"],
  },
  {
    path: "/role/permission",
    component: lazy(() => import("../../view/pages/role/permission")),
    layout: "VerticalLayout",
    auth: true,
    access: ["role-store", "role-update"],
  },
  {
    path: "/permission/data",
    component: lazy(() => import("../../view/pages/permission/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["role-store", "role-update"],
  },
  {
    path: "/permission/form",
    component: lazy(() => import("../../view/pages/permission/form")),
    layout: "VerticalLayout",
    auth: true,
    access: [
      "role-store",
      "role-update",
      "sync-permission-index",
      "sync-permission-update",
    ],
  },
  {
    path: "/profile/my-profile",
    component: lazy(() => import("../../view/pages/profile/my-profile")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
  {
    path: "/profile/change-profile",
    component: lazy(() => import("../../view/pages/profile/change-profile")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
  {
    path: "/profile/change-password",
    component: lazy(() => import("../../view/pages/profile/change-password")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
];

export default PagesRoutes;
