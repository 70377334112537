export const api_base_url = process.env.REACT_APP_BASE_URL + "/";
export const api_dashboard = process.env.REACT_APP_BASE_URL + "/dashboard/";

export const API_URL = {
  // Authentication
  login: api_base_url + "auth/login",
  forgotPassword: api_base_url + "auth/send-password-email",

  // Dashboard
  stats: api_dashboard + "stats",
  pengajuan: api_dashboard + "pengajuan",
  dealing: api_dashboard + "data-dealing",
  user: api_dashboard + "user",
  role: api_dashboard + "role",
  permission: api_dashboard + "permission",
  system: api_dashboard + "system",
  criteria: api_dashboard + "criteria",
  insuranceFee: api_dashboard + "insurance-fee",
  probability: api_dashboard + "probability",

  // Profile
  select2: api_base_url + "data/select2",
  profile: api_dashboard + "profile",
};
